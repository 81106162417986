import React from 'react'
import {FormattedMessage} from 'react-intl'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Field, Form} from 'react-final-form'
import {TextField} from 'final-form-material-ui'
import {withStyles, Typography} from '@material-ui/core'
import {grey} from '@material-ui/core/colors'
import QueueIcon from '@material-ui/icons/Queue'

const styles = () => ({
  copyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    marginBottom: '20px',
    '& > .MuiSvgIcon-root': {
      cursor: 'pointer',
      position: 'absolute',
      right: '0',
      bottom: '5px',
      color: grey[700],
      transition: 'color .3s',
      '&:hover': {
        color: grey[600]
      }
    }
  }
})

const DecryptedContainer = ({initialValues, createSnackbar, classes}) => {

  const onCopy = () => createSnackbar({message: 'info.copied', variant: 'info'})

  return (
    <Form
      onSubmit={() => {}}
      initialValues={initialValues}
      render={({handleSubmit: handleFormSubmit}) => (
        <form onSubmit={handleFormSubmit}>
          <Typography variant='h5'>
            <FormattedMessage id='header.decryptedContainer' />
          </Typography>

          <div className={classes.copyContainer}>
            <Field
              fullWidth
              name='address'
              type='password'
              component={TextField}
              label={<FormattedMessage id='form.address.label' />}
              InputProps={{readOnly: true}}
            />
            <CopyToClipboard text={initialValues.address} onCopy={onCopy}>
              <QueueIcon />
            </CopyToClipboard>
          </div>

          <div className={classes.copyContainer}>
            <Field
              fullWidth
              name='privateKey'
              type='password'
              component={TextField}
              label={<FormattedMessage id='form.privateKey.label' />}
              InputProps={{readOnly: true}}
            />
            <CopyToClipboard text={initialValues.privateKey} onCopy={onCopy}>
              <QueueIcon />
            </CopyToClipboard>
          </div>

          <div className={classes.copyContainer}>
            <Field
              fullWidth
              name='publicKey'
              type='password'
              component={TextField}
              label={<FormattedMessage id='form.publicKey.label' />}
              InputProps={{readOnly: true}}
            />
            <CopyToClipboard text={initialValues.publicKey} onCopy={onCopy}>
              <QueueIcon />
            </CopyToClipboard>
          </div>
        </form>
      )}
    />
  )
}

export default withStyles(styles)(DecryptedContainer)
