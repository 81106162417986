import {addLocaleData} from 'react-intl'

export const locales = [
  {locale: 'en', route: '/', intl: 'en', default: true},
  {locale: 'uk', route: '/uk', intl: 'uk'}
]

const getLocaleFromPathname = () => {
  const availableLocales = locales.reduce((total, {locale}, index) => {
    const divider = index !== 0 ? '|' : ''
    return `${total}${divider}(${locale})`
  }, '')

  const availableLocalesRegex = new RegExp(`(${availableLocales})($)`, 'gi')
  const localeMatch = window.location.pathname.match(availableLocalesRegex)

  if (!localeMatch) {return locales.find(locale => locale.default).locale}

  return localeMatch[0].replace(/\//g, '')
}

export const loadLocale = async () => {
  const localeName = getLocaleFromPathname()
  const locale = locales.find(({ locale }) => locale === localeName)

  // if (!window.Intl) {
  //   await import('intl')
  //   await import(`intl/locale-data/jsonp/${locale.intl}.js`)
  // }

  const localeData = (await import(`react-intl/locale-data/${locale.intl}.js`)).default
  const messages = (await import(`../translations/${locale.locale}.json`)).default

  addLocaleData(localeData)
  return {locale: locale, messages}
}
