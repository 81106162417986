import {PureComponent} from 'react'
import {injectIntl} from 'react-intl'

class SnackbarNotifications extends PureComponent {
  displayed = []
  
  componentDidUpdate() {
    const {notifications = [], enqueueSnackbar, removeSnackbar, closeSnackbar, intl} = this.props
    notifications.forEach(({key, message, options = {}}) => {
      if (this.displayed.includes(key)) return
      const msg = Boolean(intl.messages[message]) ? intl.formatMessage({id: message}) : message
      enqueueSnackbar(msg, {
        ...options,
        onClose: (event, reason, onCloseKey) => {
          if (options.onClose) options.onClose(event, reason, onCloseKey)
          removeSnackbar(onCloseKey)
        },
        onClick: () => closeSnackbar(key)
      })
      this.storeDisplayed(key)
    })
  }

  storeDisplayed = id => this.displayed = [...this.displayed, id]

  render = () => null
}

export default injectIntl(SnackbarNotifications)
