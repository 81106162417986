import React, {memo} from 'react'
import {CssBaseline, makeStyles, Grid, Divider} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '900px',
    margin: 'auto',
    padding: theme.spacing(0, 2, 8),
    '& form, input, textfield': {
      width: '100%'
    }
  },
  logo: {
    margin: '25px auto',
    maxWidth: '345px'
  },
  devider: {
    marginBottom: '30px'
  }
}))

const Wrapper = ({children}) => {
  
  const classes = useStyles()

  return (
    <Grid container direction='column' className={classes.root}>
      <CssBaseline />
      <img className={classes.logo} src='/images/header-logo.png' alt='Ambisafe' />
      <Divider className={classes.devider} />
      {children}
    </Grid>
  )
}

export default memo(Wrapper)
