import {DECRYPT_CONTAINER_SUCCESS, DECRYPT_CONTAINER_FAILURE} from './actions'

export const initialState = {
  decryptedContainer: {}
}

export default {
  [DECRYPT_CONTAINER_SUCCESS]: (state, action) => {
    return {
      decryptedContainer: {...action.payload}
    }
  },
  [DECRYPT_CONTAINER_FAILURE]: (state, action) => {
    return state
  }
}
