import React, {memo, PureComponent} from 'react'
import {Switch, Route} from 'react-router-dom'
import Wrapper from '../Wrapper'
import DecryptContainer from '../DecryptContainer'
import RecoveryContainer from '../RecoveryContainer'

class App extends PureComponent {
  
  render() {
    const {match} = this.props

    return (
      <Wrapper>
        <Switch>
          <Route exact path={match.url} component={DecryptContainer} />
          <Route path={`${match.url}recovery`} component={RecoveryContainer} />
        </Switch>
      </Wrapper>
    )
  }
}

export default memo(App)
