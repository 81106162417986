import React from 'react'
import {Redirect} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import {Typography} from '@material-ui/core'
import DecryptedContainer from './components/DecryptedContainer'
import RecoveryForm from './components/RecoveryForm'
import CurrencyItem from './components/CurrencyItem'
import CustomDataForm from './components/CustomDataForm'

const RecoveryContainer = ({
  currencies,
  decryptedContainer,
  validWalletAddress,
  verifyWalletAddress,
  verifyTokenAddress,
  recoverETH,
  recoverTokens,
  sendCustomOperation,
  ambiVaultUserContract
}) => {
  
  if (!Object.keys(decryptedContainer).length) return <Redirect to='/' />

  return (
    <>
      <DecryptedContainer />
      
      <RecoveryForm eth onVerifyClick={verifyWalletAddress} />

      {validWalletAddress && <CustomDataForm onSendClick={sendCustomOperation} />}
      {Boolean(currencies) && Object.values(currencies).map((currency, key) => 
        <CurrencyItem
          key={key}
          address={currency.address}
          amount={currency.amount}
          decimals={currency.decimals}
          name={currency.name}
          symbol={currency.symbol}
          ambiVaultUserContract={ambiVaultUserContract}
          onRecoveryClick={currency.symbol === 'ETH' ? recoverETH : recoverTokens}
        />
      )}

      {validWalletAddress && <RecoveryForm onVerifyClick={verifyTokenAddress} />}

      {validWalletAddress && !Boolean(currencies) &&
        <Typography variant='h5'>
          <FormattedMessage id='info.emptyBalances' />
        </Typography>
      }
    </>
  )
}

export default RecoveryContainer
