import {
  VERIFY_WALLET_ADDRESS_SUCCESS,
  VERIFY_TOKEN_ADDRESS_SUCCESS,
  SEND_CUSTOM
} from './actions'

export const initialState = {
  currencies: {},
  validWalletAddress: false,
  ambiVaultUserContract: '',
  walletOwnerAddress: '',
  customCallData: {},
}

export default {
  [VERIFY_WALLET_ADDRESS_SUCCESS]: (state, action) => {
    const {currencies, address, ambiVaultUserContract, walletOwnerAddress} = action
    return {
      ...state,
      currencies: {...state.currencies, ...currencies},
      validWalletAddress: address,
      ambiVaultUserContract,
      walletOwnerAddress
    }
  },
  [VERIFY_TOKEN_ADDRESS_SUCCESS]: (state, action) => {
    const {validToken} = action
    return {
      ...state,
      currencies: {...state.currencies, ...validToken}
    }
  },
  [SEND_CUSTOM]: (state, action) => {
    const {customCallData} = action
    return {
      ...state,
      customCallData
    }
  }
}
