import {connect} from 'react-redux'
import {verifyWalletAddress, verifyTokenAddress, recoverETH, recoverTokens, sendCustomOperation} from './actions'
import component from './component'

const mapStateToProps = state => {
  return {
    currencies: state.app.currencies,
    decryptedContainer: state.app.decryptedContainer,
    validWalletAddress: state.app.validWalletAddress,
    ambiVaultUserContract: state.app.ambiVaultUserContract,
  }
}

const mapDispatchToProps = (dispatch) => ({
  verifyWalletAddress: walletAddress => dispatch(verifyWalletAddress(walletAddress)),
  verifyTokenAddress: tokenAddress => dispatch(verifyTokenAddress(tokenAddress)),
  recoverETH: (destination) => dispatch(recoverETH(destination)),
  recoverTokens: (symbol, destination) => dispatch(recoverTokens(symbol, destination)),
  sendCustomOperation: customCallData => dispatch(sendCustomOperation(customCallData))
})

export default connect(mapStateToProps, mapDispatchToProps)(component)
