import {ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR} from './actions'

export const initialState = {
  snackbarNotifications: []
}

export default {
  [ENQUEUE_SNACKBAR]: (state, action) => {
    const notifications = state.snackbarNotifications ? state.snackbarNotifications : []
    return {
      ...state,
      snackbarNotifications: [
        ...notifications,
        {key: action.key, ...action.notification}
      ]
    }
  },
  [CLOSE_SNACKBAR]: (state, action) => {
    return {
      ...state,
      snackbarNotifications: state.snackbarNotifications.map(notification =>
        action.dismissAll || notification.key === action.key
          ? {...notification, dismissed: true}
          : {...notification}
      )
    }
  },
  [REMOVE_SNACKBAR]: (state, action) => {
    return {
      ...state,
      snackbarNotifications: state.snackbarNotifications.filter(notification => notification.key !== action.key)
    }
  }
}
