import React from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import {Field, Form} from 'react-final-form'
import {TextField} from 'final-form-material-ui'
import {withStyles, Button, IconButton} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import {validateContainer} from '../shared/validations'

const styles = (theme) => ({
  pswdContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    margin: '20px 0 40px',
    '& > button': {
      position: 'absolute',
      right: '0'
    }
  }
})

const DecryptContainer = ({decryptContainer, classes, intl}) => {
  
  const [isPswdVisible, setPswdVisibility] = React.useState(false)

  const togglePasswordVisibility = () => setPswdVisibility(!isPswdVisible)
  const handleSubmit = values => decryptContainer(values)

  return (
    <Form
      onSubmit={handleSubmit}
      render={({handleSubmit: handleFormSubmit, pristine, submitting, hasValidationErrors, values}) => (
        <form onSubmit={handleFormSubmit}>
          <Field
            fullWidth
            required
            multiline
            rows='8'
            name='encryptedContainer'
            type='textarea'
            component={TextField}
            label={<FormattedMessage id='form.container.label' />}
            validate={value => validateContainer(value, intl)}
          />

          <div className={classes.pswdContainer}>
            <Field
              fullWidth
              required
              name='password'
              type={isPswdVisible ? 'text' : 'password'}
              component={TextField}
              label={<FormattedMessage id='form.password.label' />}
            />
            <IconButton
              aria-label='toggle password visibility'
              onClick={togglePasswordVisibility}
            >
              {isPswdVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </div>

          <Button
            size='large'
            color='primary'
            type='submit'
            variant='contained'
            disabled={pristine || submitting || hasValidationErrors || Object.keys(values).length !== 2}
          >
            <FormattedMessage id='button.decrypt' />
          </Button>
        </form>
      )}
    />
  )
}

export default injectIntl(withStyles(styles)(DecryptContainer))
