import React from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import BigNumber from 'bignumber.js'
import {TextField} from 'final-form-material-ui'
import {withStyles, Paper, Button, Typography} from '@material-ui/core'
import {Field, Form} from 'react-final-form'
import {validateEthereumAddress} from '../../../shared/validations'

const styles = () => ({
  root: {
    margin: '5px 0',
    padding: '8px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:first-of-type': {
      marginTop: '20px'
    },
  },
  currencyItemForm: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > button': {
      margin: '6px 0 0 20px '
    }
  },
  currencyInfo: {
    width: '50%',
    paddingRight: '15px'
  },
})

const CurrencyItem = ({address, amount, decimals, name, symbol, ambiVaultUserContract, onRecoveryClick, classes, intl}) => {

  const handleSubmit = ({destination}) => {
    if (address) onRecoveryClick(symbol, destination)
    else onRecoveryClick(destination)
  }
  const balance = new BigNumber(amount).div(10 ** decimals).toString(10)

  return (
    <>
      <Paper className={classes.root}>
        <Typography className={classes.currencyInfo} variant='subtitle1'>
          {name} ({symbol})
        </Typography>
        <Typography className={classes.currencyInfo} variant='subtitle1'>
          <FormattedMessage id='amount' />
          {balance}
        </Typography>

        <Form
        onSubmit={handleSubmit}
        render={({handleSubmit: handleFormSubmit, pristine, submitting, hasValidationErrors}) => (
          <form onSubmit={handleFormSubmit} className={classes.currencyItemForm}>
            {amount > 0 && ambiVaultUserContract &&
              <Field
                fullWidth
                required
                name='destination'
                type='text'
                component={TextField}
                label={<FormattedMessage id={'form.destinationAddress.label'} />}
                validate={value => validateEthereumAddress(value, intl)}
              />
            }

            {amount > 0 &&
              <Button
                size='large'
                color='primary'
                variant='outlined'
                type='submit'
                disabled={(pristine || submitting || hasValidationErrors) && ambiVaultUserContract !== undefined}
              >
                <FormattedMessage id='button.recover' />
              </Button>
            }
          </form>
        )}
        />
      </Paper>

    </>
  )
}

export default injectIntl(withStyles(styles)(CurrencyItem))