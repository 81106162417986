import {connect} from 'react-redux'
import {withSnackbar} from 'notistack'
import {removeSnackbar} from './actions'
import component from './component'

const mapStateToProps = state => {
  return {
    notifications: state.app.snackbarNotifications
  }
}

const mapDispatchToProps = dispatch => ({
  removeSnackbar: () => dispatch(removeSnackbar())
})

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(component))
