export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CREATE_SNACKBAR = 'CREATE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

export const enqueueSnackbar = notification => {
  const key = notification.options && notification.options.key
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  }
}
export const createSnackbar = payload => ({type: CREATE_SNACKBAR, payload})
export const closeSnackbar = key => ({type: CLOSE_SNACKBAR, dismissAll: !key, key})
export const removeSnackbar = key => ({type: REMOVE_SNACKBAR, key})
