export const userContractABI = [
  {
    "constant":false,
    "inputs":[
      {
        "name":"_destination",
        "type":"address"
      },{
        "name":"_value",
        "type":"uint256"
      },{
        "name":"_data",
        "type":"bytes"
      },{
        "name":"_nonce",
        "type":"uint256"
      },{
        "name":"_v",
        "type":"uint8"
      },{
        "name":"_r",
        "type":"bytes32"
      },{
        "name":"_s",
        "type":"bytes32"
      }
    ],
    "name":"forwardOnBehalf",
    "outputs":[],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"function"
  }
]
