export const ambiProxyABI = [
  {
    "constant": true,
    "inputs": [],
    "name": "contractOwner",
    "outputs": [
      {
        "name": "",
        "type": "address"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  }
]
