import React from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import {Field, Form} from 'react-final-form'
import {TextField} from 'final-form-material-ui'
import {withStyles, Divider, Button, Typography} from '@material-ui/core'
import {validateEthereumData, validateEthereumAddress} from '../../../shared/validations'


const styles = () => ({
  devider: {
    margin: '45px 0 30px'
  },
  customDataForm: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > button': {
      margin: '6px 0 0 20px '
    }
  }
})

const CustomDataForm = ({onSendClick, classes, intl}) => {
  const handleSubmit = (values) => onSendClick(values)

  return (
    <>
      <Divider className={classes.devider} />
      
      <Typography variant='h5'>
        <FormattedMessage id={'header.customOperationContainer'} />
      </Typography>

      <Form
        onSubmit={handleSubmit}
        render={({handleSubmit: handleFormSubmit, pristine, submitting, hasValidationErrors, values}) => (
          <form onSubmit={handleFormSubmit}>
            <div className={classes.customDataForm}>
              <Field
                fullWidth
                required
                name='data'
                type='textarea'
                multiline
                rows='4'
                component={TextField}
                label={<FormattedMessage id={'form.customData.label'} />}
                validate={value => validateEthereumData(value, intl)}
              />
            </div>
            <div className={classes.customDataForm}>
              <Field
                fullWidth
                required
                name='to'
                type='text'
                component={TextField}
                label={<FormattedMessage id={'form.toAddress.label'} />}
                validate={value => validateEthereumAddress(value, intl)}
              />
              <Button
                size='large'
                color='primary'
                type='submit'
                variant='contained'
                disabled={pristine || submitting || hasValidationErrors || Object.keys(values).length !== 2}
              >
                <FormattedMessage id='button.send' />
              </Button>
            </div>
          </form>
        )}
      />

      <Divider className={classes.devider} />
    </>
  )
}

export default injectIntl(withStyles(styles)(CustomDataForm))
