import React from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import {Field, Form} from 'react-final-form'
import {TextField} from 'final-form-material-ui'
import {withStyles, Divider, Button, Typography} from '@material-ui/core'
import {validateEthereumAddress} from '../../../shared/validations'

const styles = () => ({
  devider: {
    margin: '45px 0 30px'
  },
  recoveryForm: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > button': {
      margin: '6px 0 0 20px '
    }
  }
})


const RecoveryForm = ({eth, onVerifyClick, classes, intl}) => {

  const handleSubmit = ({address}) => onVerifyClick(address)
  
  const formName = eth ? 'header.addressContainer' : 'header.tokensContainer'
  const formFieldLabel = eth ? 'form.walletAddress.label' : 'form.tokenAddress.label'

  return (
    <>
      <Divider className={classes.devider} />
      
      <Typography variant='h5'>
        <FormattedMessage id={formName} />
      </Typography>

      <Form
        onSubmit={handleSubmit}
        render={({handleSubmit: handleFormSubmit, pristine, submitting, hasValidationErrors}) => (
          <form onSubmit={handleFormSubmit} className={classes.recoveryForm}>
            <Field
              fullWidth
              required
              name='address'
              type='text'
              component={TextField}
              label={<FormattedMessage id={formFieldLabel} />}
              validate={value => validateEthereumAddress(value, intl)}
            />
            <Button
              size='large'
              color='primary'
              type='submit'
              variant='contained'
              disabled={pristine || submitting || hasValidationErrors}
            >
              <FormattedMessage id='button.verify' />
            </Button>
          </form>
        )}
      />
    </>
  )
}

export default injectIntl(withStyles(styles)(RecoveryForm))
