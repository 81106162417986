import React from 'react'
import ReactDOM from 'react-dom'
import {Router, Switch, Route} from 'react-router-dom'
import {Provider} from 'react-redux'
import {IntlProvider} from 'react-intl'
import {SnackbarProvider} from "notistack"
import {ThemeProvider} from '@material-ui/styles'

import store from './config/store'
import {locales, loadLocale} from './config/locales'
import history from './config/history'
import theme from './config/theme'
import DrizzleLoading from './components/DrizzleLoading'
import SnackbarNotifications from './components/SnackbarNotifications'
import App from './components/App'

export const renderApp = (
  locale = locales.find(locale => Boolean(locale.default)),
  messages = {}
) => (
  ReactDOM.render(
    <IntlProvider locale={locale ? locale.intl : 'en'} messages={messages}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <Router history={history}>
              <DrizzleLoading>
                <>
                  <SnackbarNotifications />
                  <Switch>
                    {locales.map((localeItem) => (
                      <Route
                        key={localeItem.locale}
                        exact={localeItem.route === '/'}
                        path={localeItem.route}
                        component={App}
                      />
                    ))}
                    <Route component={App}/>
                  </Switch>
                </>
              </DrizzleLoading>
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </IntlProvider>,
    document.getElementById('root')
  )
)

loadLocale().then(({ locale, messages }) => renderApp(locale, messages))
