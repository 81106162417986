import {connect} from 'react-redux'
import component from './component'

const mapStateToProps = state => ({
  accounts: state.accounts,
  drizzleStatus: state.drizzleStatus,
  web3: state.web3
})

export default connect(mapStateToProps)(component)
