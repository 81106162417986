export const tokenRecoveryABI = [
  {
    "constant":false,
    "inputs":[
      {
        "name":"_to",
        "type":"address"
      },{
        "name":"_value",
        "type":"uint256"
      }
    ],
    "name":"transfer",
    "outputs":[
      {
        "name":"success",
        "type":"bool"
      }
    ],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"function"
  }
]
