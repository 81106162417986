import {takeLatest, all, put} from 'redux-saga/effects'
import {CREATE_SNACKBAR, enqueueSnackbar} from './actions'

function* createSnackbarSaga({payload: {message, variant}}) {
  yield put(    
    enqueueSnackbar({
      message,
      options: {key: new Date().getTime() + Math.random(), variant}
    })
  )
}

export default function* appSagas() {
  yield all([
    yield takeLatest(CREATE_SNACKBAR, createSnackbarSaga),
  ])
}
