export const ownerABI = [
  {
    "constant":true,
    "inputs":[{
      "name":"_to",
      "type":"address"
    }],
    "name":"granted",
    "outputs":[{
      "name":"",
      "type":"bool"
    }],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  }
]
