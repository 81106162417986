import {all} from 'redux-saga/effects'
import {sagas as snackbarNotificationsSagas} from '../../components/SnackbarNotifications'
import {sagas as decryptContainerSagas} from '../../components/DecryptContainer'
import {sagas as recoveryContainerSagas} from '../../components/RecoveryContainer'

export default function* rootSaga() {
  yield all([
    snackbarNotificationsSagas(),
    decryptContainerSagas(),
    recoveryContainerSagas()
  ])
}
