export const VERIFY_WALLET_ADDRESS = 'VERIFY_WALLET_ADDRESS'
export const VERIFY_WALLET_ADDRESS_SUCCESS = 'VERIFY_WALLET_ADDRESS_SUCCESS'
export const VERIFY_TOKEN_ADDRESS = 'VERIFY_TOKEN_ADDRESS'
export const VERIFY_TOKEN_ADDRESS_SUCCESS = 'VERIFY_TOKEN_ADDRESS_SUCCESS'
export const RECOVER_ETH = 'RECOVER_ETH'
export const RECOVER_TOKENS = 'RECOVER_TOKENS'
export const SEND_CUSTOM = 'SEND_CUSTOM'

export const verifyWalletAddress = walletAddress => ({type: VERIFY_WALLET_ADDRESS, walletAddress})
export const verifyWalletAddressSuccess = (currencies, address, ambiVaultUserContract, walletOwnerAddress) => ({type: VERIFY_WALLET_ADDRESS_SUCCESS, currencies, address, ambiVaultUserContract, walletOwnerAddress})
export const verifyTokenAddress = tokenAddress => ({type: VERIFY_TOKEN_ADDRESS, tokenAddress})
export const verifyTokenAddressSuccess = (validToken) => ({type: VERIFY_TOKEN_ADDRESS_SUCCESS, validToken})
export const recoverETH = (destination) => ({type: RECOVER_ETH, destination})
export const recoverTokens = (symbol, destination) => ({type: RECOVER_TOKENS, symbol, destination})
export const sendCustomOperation = customCallData => ({type: SEND_CUSTOM, customCallData})
