import {privateToAddress, addHexPrefix, toBuffer as toBuff} from 'ethereumjs-util'
import Ambisafe from 'ambisafe-client-javascript'
import {takeLatest, all, put, fork} from 'redux-saga/effects'
import history from '../../config/history'
import {createSnackbar} from '../SnackbarNotifications/actions'
import {DECRYPT_CONTAINER, decryptContainerSuccess, decryptContainerFailure} from './actions'

function* decryptContainerSaga({payload: {encryptedContainer, password}}) {
  let privateKey, hashedPassword
  const container = JSON.parse(encryptedContainer)
  try {
    hashedPassword = Ambisafe.deriveKey(password, container.salt)
  } catch (err) {
    yield put(createSnackbar({message: 'errors.wrongContainer', variant: 'error'}))
    yield put(decryptContainerFailure())
    return
  }
  try {
    privateKey =  Ambisafe.decrypt(container.data, container.iv, hashedPassword).toString('hex')
  } catch (err) {
    yield put(createSnackbar({message: 'errors.wrongPassword', variant: 'error'}))
    yield put(decryptContainerFailure())
    return
  }
  try {
    const account = Ambisafe.fromPrivateKey(privateKey, password, container.salt)
    const decryptedContainer = {
      address: '0x' + privateToAddress(toBuff(addHexPrefix(privateKey))).toString('hex'),
      privateKey: privateKey,
      publicKey: account.data.public_key
    }
    
    yield put(decryptContainerSuccess(decryptedContainer))
    yield fork(history.replace, '/recovery')
  } catch (err) {
    yield put(createSnackbar({message: 'errors.error', variant: 'error'}))
    yield put(decryptContainerFailure())
  }
}

export default function* decryptContaineSagas() {
  yield all([
    yield takeLatest(DECRYPT_CONTAINER, decryptContainerSaga)
  ])
}
