import {
  ecsign,
  isValidAddress,
  stripHexPrefix,
  keccak256,
  bufferToHex,
  addHexPrefix,
  toBuffer as toBuff
} from 'ethereumjs-util'
import {drizzle} from '../../../config/store'
import {userContractABI} from '../abi/userContractABI'

export const assert = (condition, id) => {
  if (!condition) throw new Error(id)
}

export const buildForwardOnBehalf = (to, value, data, userContractAddress, privateKey) => {
  assert(isValidAddress(to), 'errors.invalidToAddress')
  const parsedTo = stripHexPrefix(to)
  assert(isValidAddress(userContractAddress), 'errors.invalidContractAddress')
  const parsedUserContractAddress = stripHexPrefix(userContractAddress)
  const parsedValue = drizzle.web3.utils.toBN(value).toString(16, 64)
  const parsedData = stripHexPrefix(data)
  assert(drizzle.web3.utils.isHex(parsedData), 'errors.invalidData')
  const parsedNonce = drizzle.web3.utils.toBN(Date.now()).toString(16, 64)
  const hash = keccak256(toBuff(`0x${parsedTo}${parsedValue}${parsedData}${parsedUserContractAddress}${parsedNonce}`))
  const parsedPrivateKey = addHexPrefix(privateKey)
  assert(drizzle.web3.utils.isHex(parsedPrivateKey), 'errors.invalidPrivateKey')
  const bufferPrivateKey = toBuff(parsedPrivateKey)
  const {v, r, s} = ecsign(hash, bufferPrivateKey)
  const userContract = new drizzle.web3.eth.Contract(userContractABI, 0)
  return userContract.methods.forwardOnBehalf(
    addHexPrefix(parsedTo),
    addHexPrefix(parsedValue),
    addHexPrefix(parsedData),
    addHexPrefix(parsedNonce),
    bufferToHex(v),
    bufferToHex(r),
    bufferToHex(s)).encodeABI()
}
