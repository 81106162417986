import React, {Children} from 'react'
import {Grid, makeStyles, createStyles, Typography} from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({metamaskInstallContainer: {minHeight: '100vh'}})
)

const DrizzleLoading = ({web3, accounts, drizzleStatus, loadingComp, children}) => {
  const classes = useStyles()

  if (web3.status === 'failed') {
    return (
      <Grid className={classes.metamaskInstallContainer} container direction='row' justify='center' alignItems='center'>
        <Grid item>
          <Typography variant='p'>
            This browser has no connection to the Ethereum network. Please use the Chrome/FireFox extension MetaMask, or
            dedicated Ethereum browsers Mist or Parity.
          </Typography>
        </Grid>
      </Grid>
    )
  }

  if (web3.status === 'initialized' && Object.keys(accounts).length === 0) {
    return (
      <Grid className={classes.metamaskInstallContainer} container direction='row' justify='center' alignItems='center'>
        <Grid item>
          <a href='https://metamask.io' target='_blank' rel='noopener noreferrer nofollow'>
            <img width='320px' src='/images/metamask.png' alt='Metamask download' />
          </a>
        </Grid>
      </Grid>
    )
  }

  if (drizzleStatus.initialized) return Children.only(children)

  if (loadingComp) return loadingComp

  return (
    <Grid className={classes.metamaskInstallContainer} container direction='row' justify='center' alignItems='center'>
      <Grid item>
        <Typography variant='h3'>Loading...</Typography>
      </Grid>
    </Grid>
  )
}

export default DrizzleLoading
