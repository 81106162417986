export const validateContainer = (value, intl) => {
  if (!value) return
  try {
    const container = JSON.parse(value)
    if (container) return
  }
  catch (err) {return intl.formatMessage({id: 'errors.wrongContainerFormat'})}
}

export const validateEthereumAddress = (value, intl) => {
  if (!value) return
  if (!/^(0x)?[0-9a-fA-F]{40}$/.test(value)) return intl.formatMessage({id: 'errors.wrongAddress'})
}

export const validateEthereumData = (value, intl) => {
  if (!value) return
  if (!/^0x([0-9a-fA-F]{2})*$/.test(value)) return intl.formatMessage({id: 'errors.wrongData'})
}
