export const tokenABI = [
  {
    "constant":true,
    "inputs":[],
    "name":"name",
    "outputs":[
      {
        "name":"",
        "type":"string"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },{
    "constant":true,
    "inputs":[],
    "name":"decimals",
    "outputs":[
      {
        "name":"",
        "type":"uint8"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },{
    "constant":true,
    "inputs":[
      {
        "name":"_owner",
        "type":"address"
      }
    ],
    "name":"balanceOf",
    "outputs":[
      {
        "name":"balance",
        "type":"uint256"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },{
    "constant":true,
    "inputs":[],
    "name":"symbol",
    "outputs":[
      {
        "name":"",
        "type":"string"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },{
    "constant":false,
    "inputs":[
      {
        "name":"_to",
        "type":"address"
      },{
        "name":"_value",
        "type":"uint256"
      }
    ],
    "name":"transfer",
    "outputs":[
      {
        "name":"success",
        "type":"bool"
      }
    ],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"function"
  }
]
