import createSagaMiddleware from 'redux-saga'
import {Drizzle, generateStore} from '@drizzle/store'
// eslint-disable-next-line import/no-cycle
import rootSaga from './rootSaga'
import rootReducer from './rootReducer'
import rootInitialState from './initialState'
import options from '../drizzleOptions'

const sagaMiddleware = createSagaMiddleware()

export function createReducer(initialState, handlers = {}) {
  return function reducer(state = initialState, action) {
    // eslint-disable-next-line no-prototype-builtins
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    }
    return state
  }
}

const store = generateStore({
  options,
  appReducers: {app: createReducer(rootInitialState, rootReducer)},
  appMiddlewares: [sagaMiddleware]
})

sagaMiddleware.run(rootSaga)

export const drizzle = new Drizzle(options, store)

export default store
