import axios from 'axios'

export const fetchAddressBalances = address => axios({
  method:'get',
  url: `https://web3api.io/api/v1/addresses/${address}/tokens`,
  headers: { 'x-api-key': 'UAKed36c32fa95ab635e3d129a328faf9ff' }
}).then(res => {
  if (!Boolean(res.data.payload.records.length)) return {}
  return res.data.payload.records.reduce((total, currency) => {
    return {...total, [currency.symbol]: currency} 
  }, {})
})
