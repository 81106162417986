import {takeEvery, all, call, select, put} from 'redux-saga/effects'
import {drizzle} from '../../config/store'
import {ownerABI} from '../shared/abi/ownerABI'
import {tokenABI} from '../shared/abi/tokenABI'
import {multiAccessABI} from '../shared/abi/multiAccessABI'
import {ambiProxyABI} from '../shared/abi/ambiProxyABI'
import {tokenRecoveryABI} from '../shared/abi/tokenRecoveryABI'
import {containerFromStore, walletAddressFromStore, currenciesFromStore, ambiVaultUserContractFromStore, customDataFromStore, walletOwnerAddressFromStore} from '../shared/selectors'
import {buildForwardOnBehalf} from '../shared/utils'
import {createSnackbar} from '../SnackbarNotifications/actions'
import {
  VERIFY_WALLET_ADDRESS,
  VERIFY_TOKEN_ADDRESS,
  RECOVER_ETH,
  RECOVER_TOKENS,
  SEND_CUSTOM,
  verifyWalletAddressSuccess,
  verifyTokenAddressSuccess
} from './actions'
import {fetchAddressBalances} from './api'

const zeroAddress = `0x${'0'.repeat(40)}`

function* verifyWalletAddressSaga({walletAddress}) {
  let walletOwnerAddress, granted, ambiVaultUserContract
  const {address} = yield select(containerFromStore)
  try {
    yield call(drizzle.web3.eth.getCode, walletAddress)
  } catch (err) {
    yield put(createSnackbar({message: err.message, variant: 'error'}))
    return
  }
  try {
    const wallet = new drizzle.web3.eth.Contract(ambiProxyABI, walletAddress)
    walletOwnerAddress = yield call(wallet.methods.contractOwner().call)
  } catch (err) {
    yield put(createSnackbar({message: err.message, variant: 'error'}))
    return
  }

  if (walletOwnerAddress !== zeroAddress) {
    let ownersProxies
    try {
      const multiAccess = new drizzle.web3.eth.Contract(multiAccessABI, walletOwnerAddress)
      ownersProxies = yield call(multiAccess.methods.multiAccessGetOwners().call)
    } catch (err) {
      // walletAddress is userContract -> ambiProxy(walletAddress)
    }

    // multiAccess -> ambiProxy(walletAddress)
    if (ownersProxies) {

      for (let i = 0; i < ownersProxies.length; i++) {
        try {
          const ambiProxy = new drizzle.web3.eth.Contract(ambiProxyABI, ownersProxies[i])
          const owner = yield call(ambiProxy.methods.contractOwner().call)

          const ownerContract = new drizzle.web3.eth.Contract(ownerABI, owner)
          granted = yield call(ownerContract.methods.granted(address).call)
          if (granted) {
            ambiVaultUserContract = owner
            break
          }

        } catch (err) {
          yield put(createSnackbar({message: err.message, variant: 'error'}))
          return
        }
      }

      if (!ambiVaultUserContract) {
        yield put(createSnackbar({message: 'errors.notCosigner', variant: 'error'}))
        return
      }
    // userContract -> ambiProxy(walletAddress)
    } else {
      try {
        const owner = new drizzle.web3.eth.Contract(ownerABI, walletOwnerAddress)
        granted = yield call(owner.methods.granted(address).call)
      } catch (err) {
        yield put(createSnackbar({message: err.message, variant: 'error'}))
        return
      }
      if (!granted) {
        yield put(createSnackbar({message: 'errors.error', variant: 'error'}))
        return
      }
    }

  // multiAccess(walletAddress)
  } else {
    let ownersProxies
    try {
      const multiAccess = new drizzle.web3.eth.Contract(multiAccessABI, walletAddress)
      ownersProxies = yield call(multiAccess.methods.multiAccessGetOwners().call)
    } catch (err) {
      yield put(createSnackbar({message: err.message, variant: 'error'}))
      return
    }

    for (let i = 0; i < ownersProxies.length; i++) {
      try {
        const ambiProxy = new drizzle.web3.eth.Contract(ambiProxyABI, ownersProxies[i])
        const owner = yield call(ambiProxy.methods.contractOwner().call)

        const ownerContract = new drizzle.web3.eth.Contract(ownerABI, owner)
        granted = yield call(ownerContract.methods.granted(address).call)
        if (granted) {
          ambiVaultUserContract = owner
          break
        }

      } catch (err) {
        yield put(createSnackbar({message: err.message, variant: 'error'}))
        return
      }
    }

    if (!ambiVaultUserContract) {
      yield put(createSnackbar({message: 'errors.notCosigner', variant: 'error'}))
      return
    }

    walletOwnerAddress = walletAddress
  }

  try {
    const ethBalance = yield call(drizzle.web3.eth.getBalance, walletAddress)
    // const records = yield call(fetchAddressBalances, walletAddress) // web3api is broken.
    const records = {}
    const balances = {ETH: {amount: ethBalance, decimals: 18, name: 'Ethereum', symbol: 'ETH'}, ...records}
    yield put(verifyWalletAddressSuccess(balances, walletAddress, ambiVaultUserContract, walletOwnerAddress))
  } catch (err) {
    yield put(createSnackbar({message: err.message, variant: 'error'}))
    return
  }


}

function* verifyTokenAddressSaga({tokenAddress}) {
  const walletAddress = yield select(walletAddressFromStore)
  try {
    yield call(drizzle.web3.eth.getCode, walletAddress)
    const wallet = new drizzle.web3.eth.Contract(ambiProxyABI, walletAddress)
    const token = new drizzle.web3.eth.Contract(tokenABI, tokenAddress)
    if (!wallet || !token) return
    const balance = yield call(token.methods.balanceOf(walletAddress).call)
    const decimals = yield call(token.methods.decimals().call)
    const name = yield call(token.methods.name().call)
    const symbol = yield call(token.methods.symbol().call)
    const validToken = {[symbol]: {address: tokenAddress, amount: balance, decimals: decimals, name: name, symbol: symbol}}
    yield put(verifyTokenAddressSuccess(validToken))
  } catch (err) {
    yield put(createSnackbar({message: err.message, variant: 'error'}))
    return
  }
}

function* recoverETHSaga({destination}) {
  const {privateKey} = yield select(containerFromStore)
  const walletAddress = yield select(walletAddressFromStore)
  const tokens = yield select(currenciesFromStore)
  const {amount} = tokens['ETH']
  const ambiVaultUserContract = yield select(ambiVaultUserContractFromStore)
  const walletOwnerAddress = yield select(walletOwnerAddressFromStore)

  try {
    const metaMaskAccounts = yield call(drizzle.web3.eth.getAccounts)
    const metaMaskAddress = metaMaskAccounts[0]

    const etherReceiver = ambiVaultUserContract ? destination : metaMaskAddress

    let signaturesRequired, userContractAddress, finalData, finalTo, finalAmount
    if (ambiVaultUserContract) {
      //ambi vault
      const multiAccess = new drizzle.web3.eth.Contract(multiAccessABI, walletOwnerAddress)
      const multiAccessCallData = multiAccess.methods.multiAccessCall(etherReceiver, amount, '0x').encodeABI()
      userContractAddress = ambiVaultUserContract
      finalData = multiAccessCallData
      finalTo = walletOwnerAddress
      finalAmount = 0
      signaturesRequired = yield call(multiAccess.methods.multiAccessRequired().call)
    } else {
      //regular User Contract
      const wallet = new drizzle.web3.eth.Contract(ambiProxyABI, walletAddress)
      userContractAddress = yield call(wallet.methods.contractOwner().call)
      finalData = '0x'
      finalTo = etherReceiver
      finalAmount = amount
    }

    const result = buildForwardOnBehalf(finalTo, finalAmount, finalData, userContractAddress, privateKey)
    const tx = drizzle.web3.eth.sendTransaction({
      to: userContractAddress,
      value: 0,
      data: result,
      gas: 1000000,
      from: metaMaskAddress
    })
    if (result && tx) {
      if (signaturesRequired > 1) {
        yield put(createSnackbar({message: 'info.recoverByOthers', variant: 'info'}))
      }
      return
    }

  } catch (err) {
    const msg = err.message ? err.message : 'errors.error'
    yield put(createSnackbar({message: msg, variant: 'error'}))
  }
}

function* recoverTokensSaga({symbol, destination}) {
  const {privateKey} = yield select(containerFromStore)
  const walletAddress = yield select(walletAddressFromStore)
  const tokens = yield select(currenciesFromStore)
  const ambiVaultUserContract = yield select(ambiVaultUserContractFromStore)
  const walletOwnerAddress = yield select(walletOwnerAddressFromStore)

  try {
    const metaMaskAccounts = yield call(drizzle.web3.eth.getAccounts)
    const metaMaskAddress = metaMaskAccounts[0]
    const {address, amount} = tokens[symbol]
    const token = new drizzle.web3.eth.Contract(tokenRecoveryABI, 0)

    const tokensReceiver = ambiVaultUserContract ? destination : metaMaskAddress

    const transferData = token.methods.transfer(tokensReceiver, amount).encodeABI()

    let signaturesRequired, userContractAddress, finalData, finalTo
    if (ambiVaultUserContract) {
      //ambi vault
      const multiAccess = new drizzle.web3.eth.Contract(multiAccessABI, walletOwnerAddress)
      const multiAccessCallData = multiAccess.methods.multiAccessCall(address, 0, transferData).encodeABI()
      userContractAddress = ambiVaultUserContract
      finalData = multiAccessCallData
      finalTo = walletOwnerAddress
      signaturesRequired = yield call(multiAccess.methods.multiAccessRequired().call)
    } else {
      //regular User Contract
      const wallet = new drizzle.web3.eth.Contract(ambiProxyABI, walletAddress)
      userContractAddress = yield call(wallet.methods.contractOwner().call)
      finalData = transferData
      finalTo = address
    }

    const result = buildForwardOnBehalf(finalTo, 0, finalData, userContractAddress, privateKey)
    const tx = drizzle.web3.eth.sendTransaction({
      to: userContractAddress,
      value: 0,
      data: result,
      gas: 1000000,
      from: metaMaskAddress
    })
    if (result && tx) {
      if (signaturesRequired > 1) {
        yield put(createSnackbar({message: 'info.recoverByOthers', variant: 'info'}))
      }
      return
    }
  } catch (err) {
    const msg = err.message ? err.message : 'errors.error'
    yield put(createSnackbar({message: msg, variant: 'error'}))
  }
}

function* sendCustomOperationSaga() {
  const {privateKey} = yield select(containerFromStore)
  const walletAddress = yield select(walletAddressFromStore)
  const ambiVaultUserContract = yield select(ambiVaultUserContractFromStore)
  const walletOwnerAddress = yield select(walletOwnerAddressFromStore)
  const {to, data} = yield select(customDataFromStore)


  try {
    const metaMaskAccounts = yield call(drizzle.web3.eth.getAccounts)
    const metaMaskAddress = metaMaskAccounts[0]

    let signaturesRequired, userContractAddress, finalData, finalTo
    if (ambiVaultUserContract) {
      //ambi vault
      const multiAccess = new drizzle.web3.eth.Contract(multiAccessABI, walletOwnerAddress)
      const multiAccessCallData = multiAccess.methods.multiAccessCall(to, 0, data).encodeABI()
      userContractAddress = ambiVaultUserContract
      finalData = multiAccessCallData
      finalTo = walletOwnerAddress
      signaturesRequired = yield call(multiAccess.methods.multiAccessRequired().call)
    } else {
      //regular User Contract
      const wallet = new drizzle.web3.eth.Contract(ambiProxyABI, walletAddress)
      userContractAddress = yield call(wallet.methods.contractOwner().call)
      finalData = data
      finalTo = to
    }

    const result = buildForwardOnBehalf(finalTo, 0, finalData, userContractAddress, privateKey)
    const tx = drizzle.web3.eth.sendTransaction({
      to: userContractAddress,
      value: 0,
      data: result,
      gas: 1000000,
      from: metaMaskAddress
    })
    if (result && tx) {
      if (signaturesRequired > 1) {
        yield put(createSnackbar({message: 'info.recoverByOthers', variant: 'info'}))
      }
      return
    }

  } catch (err) {
    const msg = err.message ? err.message : 'errors.error'
    yield put(createSnackbar({message: msg, variant: 'error'}))
  }
}

export default function* recoveryContainerSagas() {
  yield all([
    yield takeEvery(VERIFY_WALLET_ADDRESS, verifyWalletAddressSaga),
    yield takeEvery(VERIFY_TOKEN_ADDRESS, verifyTokenAddressSaga),
    yield takeEvery(RECOVER_ETH, recoverETHSaga),
    yield takeEvery(RECOVER_TOKENS, recoverTokensSaga),
    yield takeEvery(SEND_CUSTOM, sendCustomOperationSaga)
  ])
}
