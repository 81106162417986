import {createMuiTheme} from '@material-ui/core/styles'
import {grey} from '@material-ui/core/colors'

const theme = createMuiTheme({
  palette: {primary: {main: grey[700]}},
  typography: {
    h5: {
      fontSize: 15,
      fontWeight: 500,
      color: grey[700],
      paddingBottom: 10
    },
    h6: {
      fontSize: 15,
      fontWeight: 600,
      textTransform: 'uppercase'
    },
    body1: {
      fontSize: 15
    },
    subtitle1: {
      fontSize: 15,
      fontWeight: 600,
      color: grey[700]
    }
  }
})

export default theme
