import {connect} from 'react-redux'
import {createSnackbar} from '../../../SnackbarNotifications/actions'
import component from './component'

const mapStateToProps = state => {
  return {
    initialValues: state.app.decryptedContainer
  }
}

const mapDispatchToProps = (dispatch) => ({
  createSnackbar: payload => dispatch(createSnackbar(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(component)
